@import 'src/styles/mixins';

.hint {
  color: $textColorLight;
  font-size: 1.2rem;
  margin-top: $gapXL;
}

.input {
  margin-bottom: $gapXL + $gapS;
  margin-top: $gapXL;
  width: 22.5rem;
}

.button {
  width: 22.5rem;
}

.error {
  bottom: -3rem;
  color: $errorColor;
  position: absolute;
}

