@import 'src/styles/mixins';

.form {
  display: flex;
  flex-direction: row;
  gap: $gapXS;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: $gapM;
}

.select {
  width: 32rem;
}

.label {
  @include font-size-m;
  box-sizing: border-box;
  color: $textColor;
  margin-right: $gapS;
}
