@import 'src/styles/mixins';

$logoutIconSize: 3rem;

.userData {
  display: flex;
  column-gap: $gapS;
  align-items: center;
  justify-content: center;
}

.userName {
  @include font-size-s;
  color: $textColor;
  white-space: nowrap;
}

.details {
  @include font-size-s;
  display: flex;
  flex-direction: column;
  color: $textColorLight;
  white-space: nowrap;

  @include tablet-only {
    @include font-size-xs;
  }
}

.logoutButton {
  min-width: $logoutIconSize;
  padding: 0;
  background: transparent;

  &:hover {
    background: $lightShadowColor;
  }
}

.logoutIcon {
  @include size($logoutIconSize);
  align-items: center;
  display: flex;
  justify-content: center;
}

.systemDate {
  display: flex;
  flex-direction: row;
  column-gap: 0.3rem;

  @include tablet-only {
    flex-direction: column;;
  }
}
