@import 'src/styles/mixins';

.fields {
  border-bottom: 1px solid $lineColor;
  margin-bottom: $gapXL + $gapXS;
  padding-bottom: $gapS;
  display: flex;
  flex-direction: column;
  gap: $gapS;
}

.row {
  display: flex;
  margin-bottom: $gapS;

  &_endAligned {
    margin-top: auto;
  }
}

.fieldRow {
  display: flex;
  gap: $gapS;
}

.type {
  min-width: 13rem;
}

.fieldInput {
  width: 20rem;
}