@import 'src/styles/mixins';

.selectCustomer {
  color: $primaryColor;
  cursor: pointer;
  text-align: right;
}

.row {
  @include default-transition;

  &:hover {
    background: $rowBgColor;
  }
}

