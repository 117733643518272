@import 'src/styles/mixins';

.snackbarWrapper {
  position: fixed; // added to support older browsers
  position: sticky; // sass-lint:disable-line no-duplicate-properties
  top: 0;
  width: 100%;
  z-index: $zIndexSnackbar;
}

.snackbar {
  position: absolute;
  top: 0;
  width: 100%;
}

.message {
  @include desktop-width;
  text-align: center;
  word-break: break-word;
}

.messageBody {
  display: flex;
  flex-direction: column;
}

.content {
  @include font-size-s;
  background: $successBgColor;
  border-bottom-left-radius: $borderRadius;
  border-bottom-right-radius: $borderRadius;
  box-shadow: 0 0 0.8rem 0.2rem $lineColor;
  color: $successColor;
  flex-grow: 1;
  flex-wrap: nowrap;
  padding-bottom: $gapM;
  padding-top: $gapM;

  path {
    fill: $successBgColor;
  }

  &_error {
    background: $errorBgColor;
    color: $errorColor;

    path {
      fill: $errorColor;
    }
  }
}
