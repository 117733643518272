@import 'src/styles/mixins';

.status {
  @include rounded-corners;
  align-items: center;
  background: $primaryBgColor;
  color: $primaryColor;
  display: inline-flex;
  height: 2.5rem;
  justify-content: center;
  padding-left: $gapM;
  padding-right: $gapM;

  &_submitted {
    background: $successBgColor;
    color: $successColor;
  }

  &_pendingApproval {
    background: $warningBgColor;
    color: $warningColor;
  }

  &_error {
    background: $errorBgColor;
    color: $errorColor;
  }

  &_disabled {
    background: $rowBgColor;
    color: $textColorLight;

    path {
      fill: $textColorLight;
    }

  }
}

.label {
  @include text-overflow-ellipsis;
}

.icon {
  @include size(1.6rem);
  align-items: center;
  display: flex;
  margin-right: $gapXS;
}
