@import 'src/styles/mixins';

$desktopWidth: 100rem;
$tabletWidth: calc(100vw - 8rem); // 100% including side panel paddings

.panelWrapper {
  @include desktop-only {
    max-width: $desktopWidth;
    width: $desktopWidth;
  }

  max-width: $tabletWidth;
  width: $tabletWidth;
}
