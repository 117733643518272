@import 'src/styles/mixins';

.input{
  margin-bottom: $gapXL;
  width: 100%;
}

.button {
  margin-top: $gapM;
}

.error {
  bottom: -3rem;
  color: $errorColor;
  position: absolute;
}
