@import 'src/styles/mixins';

.container {
  margin-top: -$gapXL; // TODO fix after big margin in NxPopup content changed
  width: 100%;
}

.description {
  @include font-size-s;
  color: $textColorLight;
  margin-bottom: $gapL;
  text-align: center;
}

.button {
  margin-left: $gapL;
}

.input {
  margin-bottom: $gapL;
}

.header {
  max-width: 37rem;
}

.buttonRow {
  display: flex;
  justify-content: flex-end;
}
