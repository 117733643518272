@import 'src/components/shared/search-bar/SearchBar.module';
@import 'src/styles/mixins';

$searchIconSize: 3.6rem;
$searchInputWidth: 18rem;

.formRow {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5 * $baseGap;

  & + .formRow {
    border-bottom: 1px solid $lineColor;
  }
}

.input,
.searchButtonWrapper {
  flex: 1;
  max-width: calc(25% - #{$gapM});
}

.searchButton {
  min-width: $searchIconSize;
  padding: 0;
}

@import 'src/components/shared/search-bar/SearchBar.module';
.searchIcon {
  @include search-icon($searchIconSize);
}
