@import 'src/styles/mixins';

.menu {
  margin-top: $gapM;
}

.list {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.headerIcon {
  border-radius: $borderRadius;

  path {
    @include default-transition;
  }

  &_active {
    background: $primaryBgColor;

    path {
      fill: $primaryColor;
    }
  }
}
