@import 'src/styles/mixins';

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 2 * $gapXL;
}

.header {
  @include font-size-s;
  align-items: center;
  color: $textColorLight;
  display: flex;
  justify-content: space-between;
  margin-bottom: $gapXL;
  width: 100%;
}

.title {
  margin-bottom: 0;
}

.tabsWrapper {
  width: 100%;
}
