@import 'src/styles/mixins';

.input {
  margin-right: $gapL;
  width: $customFieldDefaultWidth;
}

.fields {
  border-bottom: 1px solid $lineColor;
  margin-bottom: $gapXL + $gapXS;
  padding-bottom: $gapS;
}

.row {
  display: flex;
  margin-bottom: $gapS;

  &_endAligned {
    margin-top: auto;
  }
}

.add {
  margin-left: $gapS;
}
