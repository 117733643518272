@import 'src/styles/mixins';

.topDetails {
  @include default-transition;
  border: 1px solid $lineColor;
  border-radius: $borderRadius;
  display: block;
  min-height: 7rem;
  padding-left: $gapM;
  padding-right: $gapM;
  position: relative;
}
