@import 'src/styles/mixins';

.labelContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-right: $gapXL;
  max-width: $inputMaxWidth;
  position: relative;
}

.labelContent {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: $inputMaxWidth - 10rem;
}

.infoToolip {
  width: 2rem;
  height: 0.5rem;
}

.infoIcon {
  @include size(2rem);
  color: $textColor;
  pointer-events: auto;
  position: absolute;
  top: -0.4rem;
}
