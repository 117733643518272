@import 'src/styles/mixins';

.item {
  border: 1px solid transparent;
  border-radius: $borderRadius;
  box-sizing: border-box;
  padding-bottom: $gapM;
  padding-top: $gapM;
  transition: background 0.2s ease, border-color 0.2s ease;

  &_isDragging {
    background: $white;
    border-color: $lineColor;
  }
}

.handle {
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: $customFieldDefaultIndent;
}

.row {
  align-items: center;
  display: flex;
}
