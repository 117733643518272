@import '../../../../styles/mixins';

.description {
  margin-bottom: 1rem;
}

.text{
  margin-right: 1rem;
  color: $textColorLight;
}
