@import 'src/styles/mixins';

.details {
  align-items: center;
  display: flex;
  padding-bottom: $gapM;
  padding-top: $gapM;
}

.button {
  margin-left: $gapL;
}

.detailsList {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.moreDetails {
  @include default-transition;
  display: flex;
  max-height: 0;
  overflow: hidden;

  &_toggled {
    max-height: 20rem; // max-height bigger than all details
    padding-bottom: $gapM;
  }
}

.moreDetailsColumn {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: $gapS;
}

.moreButtonsColumn {
  display: flex;
  justify-content: flex-end;
}
