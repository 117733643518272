@import 'src/styles/mixins';

.section {
  margin-bottom: $gapXL;
  margin-top: $gapL;
  max-width: 50rem;
}

.heading {
  @include font-size-m;
  border-bottom: 1px solid $lineColor;
  color: $textColor;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin-bottom: $gapM;
  padding-bottom: $gapL;
}

.goToApplicationButton {
  position: absolute;
  bottom: $gapS;
  right: 0;
}

.grid {
  display: grid;
  gap: $gapS;
  grid-template-columns: 1fr 1fr;
}

.text {
  @include font-size-s;
}

.descriptionTitle {
  @include font-size-xs;
  color: $textColorLight;
  margin-top: $gapS;
}

.descriptionValue {
  @include font-size-s;
  margin-top: $gapS;
}

