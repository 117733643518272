@import 'src/styles/mixins';

.panel {
  @include font-size-s;
  border: 1px solid $lineColor;
  border-radius: $borderRadius;
  margin-bottom: $gapXL;
  padding-bottom: $gapS;
  padding-left: $gapL;
  padding-right: $gapL;
  padding-top: $gapS;
}

.panelHeader {
  align-items: center;
  display: flex;
  height: 5 * $baseGap;
  justify-content: space-between;
}

.detailsButton {
  @include font-size-s;
  height: 5 * $baseGap;
  margin-left: $gapL;
  margin-right: auto;
}

.moreDetails {
  @include default-transition;
  display: flex;
  max-height: 0;
  overflow: hidden;

  &_toggled {
    max-height: 20rem; // max-height bigger than all details
  }
}

.approvers {
  margin-top: $gapL;
  overflow: auto;
  width: 100%;
}
