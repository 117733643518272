@import 'src/styles/mixins';

.tableRow {
  @include default-transition;

  & > * {
    border-bottom: unset;
  }

  &:hover {
    background: $rowBgColor;
  }
}

.expandableDetails {
  padding-bottom: 0;
  padding-top: 0;
}

.expandableDetailsOpen {
  padding-bottom: $gapXS;
  padding-top: $gapXS;
}

.expandIcon {
  pointer-events: auto;
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
}

.column {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
}

.detail {
  display: flex;
  flex-direction: column;
  gap: $gapXS;
  margin-top: $gapXS;
}

.detailLabel {
  color: $textColorLight
}

.actionsColumn {
  display: flex;
  justify-content: flex-end;
}
