@import 'src/styles/mixins';

$buttonMargin: $gapL + $baseGap;
$buttonWidth: 5.3rem;

.checkboxRow {
  @include input-bottom-margin;
  max-height: $basicElementHeight;
}

.button {
  margin-left: $buttonMargin;
}

.input {
  flex: 1;
  max-width: $inputMaxWidth;
}

.noButtonMargin {
  // Margin needed to keep input consistent with others when required button is hidden.
  margin-right: $buttonMargin + $buttonWidth;
}
