@import 'src/styles/mixins';

.cancel {
  border-color: $errorColor;
  color: $errorColor;

  path {
    fill: $errorColor;
  }

  &:hover{
    border-color: $errorColor;
  }
}
