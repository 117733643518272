@import 'src/styles/mixins';

.startButton {
  path {
    @include default-transition;
  }

  &_disabled {
    path {
      fill: $disabledTextColor;
    }
  }
}
