@import 'src/styles/mixins';

.header {
  display: flex;
  flex-direction: column;
}

.select{
  text-align: left;
}
