@import 'src/styles/mixins';
@import 'src/components/shared/search-bar/advanced-search-menu/AdvancedSearchMenu.module';

.section {
  @include advanced-search-section;
}

.description {
  @include advanced-search-description;
}

.checkboxes {
  @include advanced-search-checkboxes;
}
