@import 'src/styles/mixins';

.button {
  @include size($basicElementHeight);
  align-items: center;
  border: 1px solid transparent;
  border-radius: $borderRadius;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;

  &_bordered {
    border-color: $lineColor;
  }

  &_disabled {
    cursor: default;
    pointer-events: none;
  }
}

.iconWrapper {
  @include default-transition;
  @include size(3.2rem);
  align-items: center;
  border-radius: $borderRadius;
  display: flex;
  justify-content: center;
  position: relative;

  &_checked {
    background: $switchBgColor;

    path {
      fill: $textColor;
    }
  }
}
