// sass-lint:disable no-duplicate-properties

// Main app font Nunito is provided by @nextbank/ui-components

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: bold;
  src: url('../assets/fonts/Raleway-ExtraBold.eot');
  src: url('../assets/fonts/Raleway-ExtraBold.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/Raleway-ExtraBold.woff') format('woff'), url('../assets/fonts/Raleway-ExtraBold.ttf') format('truetype');
}
