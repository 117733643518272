@import 'src/styles/mixins';

.fullNameHeader {
  width: 20rem;
}

.customerActions{
  display: flex;
  align-items: flex-end;
}
