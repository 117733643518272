@import 'src/styles/mixins';

.stepHeading {
  display: flex;
  margin-bottom: $gapM;
}

.stepName {
  margin-bottom: 0;
}
