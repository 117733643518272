@import 'src/styles/mixins';

.tableRow {
  @include default-transition;

  &:hover {
    background: $rowBgColor;
  }
}

.header {
  min-width: 8rem;
}

.buttonHeader {
  width: 3rem;
}
