@import 'src/styles/mixins';

.status {
  @include text-overflow-ellipsis;
  @include rounded-corners;
  align-items: center;
  background: $inputBgColor;
  color: $textColorLight;
  display: inline-flex;
  gap: $gapXS;
  height: 2.5rem;
  justify-content: center;
  padding-left: $gapS;
  padding-right: $gapM;
}

.successChip {
  background: $successBgColor;
  color: $successColor;
}

.warningChip {
  background: $warningBgColor;
  color: $warningColor;
}

.errorChip {
  background: $errorBgColor;
  color: $errorColor;
}

.animationContainer {
  display: flex;
  justify-content: center;
  justify-items: center;
}

.cogSpinner {
  animation: spin 2s linear infinite;
}

.uploadingPulse {
  animation: pulse 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@keyframes pulse {
  25% {
    transform: scale(0.9);
  }
  75% {
    transform: scale(1.1);
  }
}
