.principalButton {
  height: 36px;
  max-width: 80px;
  display: block;
  font-size: 1rem;
  padding-top: 2px;
  padding-bottom: 2px;

  span {
    white-space: normal;
    display: block !important;
    word-wrap: break-word;
    line-height: 1.2;
  }
}
