@import 'src/styles/mixins';

.field {
  @include input-bottom-margin;
  align-items: center;
  display: flex;
  justify-content: space-between;

  & + .field {
    border-top: 1px solid $lineColor;
    padding-top: 5 * $baseGap;
  }
}

.inputs {
  flex: 1;
  margin-right: $gapS;
}

.input {
  @include input-bottom-margin;
  width: 36rem;

  & + .input {
    margin-bottom: 0;
  }
}

.addRow {
  display: flex;
  justify-content: flex-end;
}
