@import 'src/styles/mixins';

.column {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: calc(50% - #{$gapXL});
  width: 50%;

  &:nth-child(2n) {
    margin-left: 2 * $gapXL;
  }
}
