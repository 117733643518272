@import 'src/styles/mixins';

.main {
  display: flex;
  min-height: calc(100vh);

  &_withHeader {
    @include desktop-width;
    flex-direction: column;
    min-height: calc(100vh - #{$headerHeight});
    padding-top: $gapXL;
  }
}
