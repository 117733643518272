@import 'src/styles/mixins';

$backButtonSize: 3.6rem;

.backButton {
  height: $backButtonSize;
  margin-right: $gapL;
  min-width: $backButtonSize;
  padding: 0;
}

.backArrow {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 2 * $gapXL;
}

.header {
  align-items: center;
  display: flex;
  margin-bottom: $gapXS;
  width: 100%;
}

.customerSelectContainer {
  width: 100%;
}

.customerReviewContainer {
  width: 100%;
}

.nextButtonWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
