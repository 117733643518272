@import 'src/styles/mixins';

$loaderOverlayBackground: rgba(255, 255, 255, 0.7);

.componentWrapper {
  position: relative;
}

.loaderWrapper {
  background: $loaderOverlayBackground;
  height: calc(100% - #{$gapXL});
  padding-top: $gapXL;
  position: absolute;
  width: 100%;
  z-index: $zIndexTop;
}

.tableWrapper {
  margin-top: $gapM;
}
