@import 'src/styles/mixins';

.difference {
  @include font-size-s;
  color: $textColorLight;
  word-wrap: break-word;
}

.alreadyPaid{
  left: -1rem;
  position: relative;
}
