@import 'src/styles/mixins';

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 2 * $gapXL;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.title {
  margin-bottom: 0;
}

.search {
  width: 21.5rem;
}
