@import 'src/styles/mixins';

.title {
  @include font-size-xs;
  color: $textColorLight;
}

.value {
  @include text-overflow-ellipsis;
  margin-top: auto;
  max-width: 18rem;

  &_long {
    max-width: 36rem;
  }
}

.detail {
  @include tablet-only {
    margin-right: $gapL;
  }

  @include font-size-s;
  display: flex;
  flex-direction: column;
  height: 4rem;
  margin-right: $gapL;

  &_row {
    align-items: flex-end;
    flex-direction: row;
    height: auto;
    margin-bottom: $gapS;
    margin-top: $gapS;

    .value {
      margin-left: $gapL;
      margin-top: 0;
    }
  }

  &_desktopOnly {
    @include tablet-only {
      display: none;
    }
  }
}
