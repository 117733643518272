@import 'src/styles/mixins';

.valid {
  color: $successColor;
}

.invalid {
  color: $errorColor;
}

.unknown {
  color: $warningColor;
}
