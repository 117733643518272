@import 'src/styles/mixins';

.status {
  @include rounded-corners;
  align-items: center;
  background: $successBgColor;
  color: $successColor;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  width: 6.5rem;

  &_draft {
    background: $errorBgColor;
    color: $errorColor;
  }
}
