@import 'src/styles/mixins';
@import 'src/components/shared/confirmation-popup/ConfirmationPopupSharedStyles.module';

.message {
  @include message;
}

.confirmButton {
  background: $errorColor;
}
