@import 'src/styles/mixins';

.approvalConfigurationPanel {
  border-top: 1px solid $lineColor;
  margin-top: $gapL + $gapS;
  padding-top: $gapS;
}

.instructionRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.input {
  flex: 1;
  margin-right: $gapXL;
  max-width: $customFieldDefaultWidth + 2 * $customFieldDefaultIndent;
}

.rules {
  border-bottom: 1px solid $lineColor;
  margin-bottom: $gapXL + $gapXS;
}

.row {
  display: flex;
}
