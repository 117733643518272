@import 'src/styles/mixins';

.container {
  display: grid;
  grid-template-columns: 3fr 1fr;
  padding-bottom: $gapM;
  padding-top: $gapM;
}

.details {
  display: grid;
  column-gap: $gapXS;
  row-gap: $gapS;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.buttons {
  column-gap: $gapS;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @include tablet-only {
    gap: $gapXS;
  }
}

.rowsNumber {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.icon {
  @include size(2.4rem);
  margin-right: $gapXS;
}

.validIcon {
  path {
    fill: $successColor;
  }
}

// The color property works on svg because in XML fill value = currentColor. We have different approach here as the
// common one is bugged and applies on every svg of the same type. On this screen there are 2 closeIcons and this css
// common solution would apply on both. By this new approach it behaves correctly and applies only for desired icon.
// Please do not change it or test after change by opening batchItem details the side pane close button should not
// be the same color as invalidIcon.
.invalidIcon {
  color: $errorColor;
}

.separator {
  margin-left: $gapXS;
  margin-right: $gapXS;
  padding-bottom: 0.2rem;
}
