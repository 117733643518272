@import 'src/styles/mixins';

.button {
  margin-left: $gapL;

  &:disabled {
    path {
      fill: $disabledColor;
    }
  }
}
