@import '../../../../../styles/mixins';

.input {
  margin-bottom: 5 * $baseGap;
}

.error {
  @include font-size-s;
  color: $errorColor;
  margin-bottom: $gapL;
}

.button {
  margin-top: $gapM;
  min-width: 10rem;
}
