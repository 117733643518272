@import 'src/styles/mixins';

$logoutIconSize: 3rem;

.logoutButton {
  min-width: $logoutIconSize;
  padding: 0;
  background: transparent;

  &:hover {
    background: $lightShadowColor;
  }
}

.logoutIcon {
  @include size($logoutIconSize);
  align-items: center;
  display: flex;
  justify-content: center;
}
