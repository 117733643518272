@import 'src/styles/mixins';

.row {
  @include tablet-only {
    max-width: 80rem;
  }

  display: flex;
  flex-wrap: wrap;
  gap: $gapL;
}

.nextLineMarker,
.input {
  max-width: 18.5rem;
  min-width: 18.5rem;
}

.inputSelectWrapper {
  display: flex;
}

.inputSelect {
  max-width: 15.5rem;
  flex: 1;
}

.buttons {
  display: flex;
}

.nextLineMarker {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.tabletOnly {
  @include desktop-only {
    display: none;
  }
}

.desktopOnly {
  @include tablet-only {
    display: none;
  }
}
