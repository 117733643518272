@import 'src/components/shared/search-bar/SearchBar.module';

$searchIconSize: 3.6rem;

.inputs {
  @include search-bar-inputs;

  flex-wrap: wrap;
  flex-grow: 1;

  & > div {
    flex: 1;
    min-width: 13rem;
  }

  margin-bottom: $gapL;
}

.searchIcon {
  @include search-icon($searchIconSize);
}
