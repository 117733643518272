@import 'src/styles/mixins';

.container {
  padding-top: $gapM;
}

.groupChecks {
  display: flex;
  flex-direction: column;
  margin-left: $gapXL;
  padding-bottom: $gapM;
  padding-top: $gapM;
}

.addRow {
  display: flex;
  margin-bottom: $gapM;
  margin-top: $gapL;
  padding-left: 3rem;
}

.addButton {
  margin-left: $gapM;
}

.input {
  flex: 1;
  margin-right: $gapXL;
  max-width: $customFieldDefaultWidth + $customFieldDefaultIndent;
}
