@import 'src/styles/mixins';

.row {
  display: flex;
  flex-wrap: wrap;
}

.fileUpload {
  flex-direction: column;
}
