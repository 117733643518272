@import '~@nextbank/ui-components/dist/variables';

$disabledTextColor: rgba(0, 0, 0, 0.26);
$rowBgColor: #f3f7fb;
$emptyPageBackground: #f4f9fb;

$headerHeight: 7rem;

$customFieldDefaultWidth: 20.4rem;
$customFieldDefaultIndent: $gapXL;

$drawerWidth: 35.6rem;

$zIndexSnackbar: $zIndexTop + 1;
