@import 'src/styles/mixins';

.icon {
  margin-right: $gapS;
  min-width: 2.4rem;
}

.option {
  @include font-size-m;
  justify-content: flex-start;
  min-height: 5.4rem;
  min-width: 12.6rem;
  padding-bottom: $gapM;
  padding-left: $gapL;
  padding-right: 5 * $baseGap;
  padding-top: $gapM;

  &:hover {
    background: lighten($primaryBgColor, 2);
  }
}
