@import 'src/styles/mixins';

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: $gapL;
  margin-top: $gapL;
}

.fileButtons {
  display: flex;
  flex-direction: row;
  gap: $gapM;
}
