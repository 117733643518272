@import 'src/styles/mixins';

.newGroupInput {
  flex: 1;
  margin-right: $gapXL;
  max-width: $customFieldDefaultWidth + 2 * $customFieldDefaultIndent;
}

.checkGroups {
  border-bottom: 1px solid $lineColor;
  margin-bottom: $gapXL + $gapXS;
}

.row {
  display: flex;
}
