@import 'src/styles/mixins';

.cell {
  @include font-size-s;

  color: $textColor;
  padding: $gapM;

  &Small {
    padding: $gapS;
  }

  &AlignRight {
    text-align: end;
  }
}
