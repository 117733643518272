@import 'src/styles/mixins';

$nameInputWidth: 30rem;

.input {
  margin-right: $gapL;
  width: $nameInputWidth;

  &_new {
    width: $nameInputWidth + $gapXL;
  }
}

.phases {
  border-bottom: 1px solid $lineColor;
  margin-bottom: $gapXL + $gapXS;
  padding-bottom: $gapS;
}

.row {
  display: flex;
  margin-bottom: $gapS;

  &_save {
    margin-top: $gapXL;
  }
}
