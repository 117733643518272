@import 'src/styles/mixins';

.container {
  padding-top: $gapM;
}

.groupFields {
  display: flex;
  flex-direction: column;
  margin-left: $gapXL;
  padding-bottom: $gapM;
  padding-top: $gapM;
}

.addRow {
  display: flex;
  margin-bottom: $gapM;
  margin-top: $gapM;
}

.input {
  flex: 1;
  margin-right: $gapL;
  max-width: $customFieldDefaultWidth + $customFieldDefaultIndent;
}
