@import 'src/styles/mixins';

.printApplication {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: $gapS;
  border-bottom: 1px solid $lightShadowColor;
}

.printButton {
  path {
    fill: $primaryColor;
  }
}
