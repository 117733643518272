@import 'src/styles/mixins';

.chevron {
  @include default-transition;
  margin-left: 0.1rem;
  width: 1.2rem;
  padding: $gapS;

  &_small {
    transform: scale(0.8);
  }

  &_up {
    transform: rotate(180deg);

    &.chevron_small {
      transform: scale(0.8) rotate(180deg);
    }
  }
}
