@import 'src/styles/mixins';

.topBar {
  align-items: center;
  padding-bottom: $gapM;
  padding-top: $gapM;
}

.detailsList {
  display: grid;
  grid-template-columns: 19% 19% 19% 19% 24%;
  justify-content: space-between;
}

.moreDetails {
  @include default-transition;
  display: flex;
  max-height: 0;
  overflow: hidden;

  &_toggled {
    max-height: 20rem; // max-height bigger than all details
    padding-bottom: $gapM;
  }
}

.buttonsColumn {
  display: flex;
  justify-content: flex-end;
  gap: $gapL;
}

.container {
  display: grid;
  grid-template-columns: 60% 10% 30%;
}

.noMoreContainer {
  display: grid;
  grid-template-columns: 60% 40%;
}

.moreContainer {
  @include default-transition;
  display: grid;
  grid-template-columns: 60% 40%;
  max-height: 0;
  overflow: hidden;

  &_toggled {
    margin-top: $gapM;
    max-height: 20rem; // max-height bigger than all details
  }
}
