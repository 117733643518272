@import 'src/components/shared/search-bar/SearchBar.module';
@import 'src/styles/mixins';

$searchIconSize: 4rem;

.searchForm {
  border-bottom: 1px solid $lineColor;
  display: flex;
  padding-bottom: 5 * $baseGap;
}

.searchButton {
  min-width: $searchIconSize;
}
.searchIcon {
  @include search-icon($searchIconSize);
}

.input {
  flex: 1;
  margin-right: $gapM;
}
