@import 'src/styles/mixins';

.column {
  margin-left: $gapXL;
  width: 50%;
}

.simulation {
  border: 1px solid $lineColor;
  border-radius: $borderRadius;
  margin-top: -$gapS;
  padding: $gapL;
}

.header {
  @include font-size-l;
  margin-bottom: $gapXL;
}
