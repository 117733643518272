@import 'src/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
}

.description {
  @include font-size-s;
  color: $textColorLight;
  margin-top: $gapXS;
  margin-bottom: $gapXL;
}

.table {
  margin-top: $gapXL;
}
