@import 'src/styles/mixins';

.row {
  display: flex;
  flex-wrap: wrap;
}

.inputs {
  margin-bottom: $gapXL;

  & > * {
    @include input-bottom-margin;
  }
}
