@import 'src/styles/mixins';

.customerDetails {
  display: flex;
  flex-direction: column;
  border: 1px solid $lineColor;
  border-radius: $borderRadius;
  padding: $gapM;
  margin-bottom: $gapL;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mainDetails {
  display: flex;
  align-items: center;
}

.chip {
  margin-left: $gapM;
}

.button {
  &:disabled {
    path {
      fill: $disabledColor;
    }
  }
}

