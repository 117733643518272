@import 'src/styles/mixins';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.errors {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.phaseErrors:not(:first-of-type) {
  margin-top: 1rem;
}

.error {
  display: flex;
  align-items: center;
}

.fieldName {
  font-weight: bold;
  margin: 0 0.5rem;
}
