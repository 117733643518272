@import 'src/styles/mixins';

@mixin message {
  display: flex;
  flex-direction: column;
  gap: $gapL;
}

@mixin bold {
  color: $textColor;
  font-weight: bold;
  margin-right: $gapXS;
}
