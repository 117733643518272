@import 'src/styles/mixins';

.header {
  align-items: center;
  display: flex;

  &_fixedHeight {
    min-height: 4rem;
  }
}

.bin {
  margin-left: $gapM;
}
