@import 'src/styles/mixins';

.stepHeading {
  display: flex;
  margin-bottom: $gapM;
}

.stepName {
  margin-bottom: 0;
}

.stepNavigation {
  align-items: center;
  display: flex;
  margin-left: auto;
  column-gap: $gapXS;
}
