@import 'src/styles/mixins';

.search {
  display: flex;
  border-bottom: 1px solid $lineColor;
}

.customerType {
  flex-grow: 1;
  margin-right: $gapM;
  max-width: 13rem;
}

.form {
  flex-grow: 2;
}
