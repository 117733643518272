@import 'src/styles/mixins';

$tabsHeight: 3.7rem;

.tabs {
  display: flex;
  flex-direction: row;
  min-height: $tabsHeight;
}

.tab {
  @include font-size-s;

  color: $textColorLight;
  min-height: $tabsHeight;
  min-width: auto;
  opacity: 1;
  padding-left: $gapL;
  padding-right: $gapL;

  &:not(:first-child) {
    border-left: 1px solid $lineColor;
    padding-left: calc(#{$gapL} + 1px);
  }

  &_selected {
    color: $textColor;
  }
}

.indicator {
  background: transparent;
  display: flex;
  justify-content: center;
}

.indicatorThumb {
  background: $primaryColor;
  border-radius: 0.2rem;
  height: 0.4rem;
  max-width: 1.8rem;
  width: 100%;
}
