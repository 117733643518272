@import 'src/styles/mixins';

.fileUploadWrapper {
  & > * {
    margin-bottom: 0;
  }
}

.fileUpload {
  flex-direction: column;
}
