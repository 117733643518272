@import 'src/styles/mixins';

.error {
  @include font-size-s;

  color: $errorColor;
  margin-top: 0.1rem;
}

.menu {
  background: $inputBgColor;
  list-style: none;
  margin: 0;
  max-height: 40vh;
  overflow: auto;
  padding: 8px 0;
}
