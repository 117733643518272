@import 'src/styles/mixins';

.inputs {
  display: flex;
  flex-direction: row;
  gap: $gapS;
  justify-content: flex-start;
  margin-top: $gapXS;
}

.textInput {
  min-width: 24rem;
}

.selectInput {
  min-width: 20rem;
}

.description {
  @include font-size-s;
  color: $textColorLight;
  margin-top: $gapXS;
}
