@import 'src/styles/mixins';

.cell {
  @include font-size-xs;
  color: $textColorLight;
  padding: $gapM;

  &_small {
    padding: $gapS;
  }
}
