@import 'src/styles/mixins';

.fieldRow {
  @include input-bottom-margin;
  align-items: center;
  display: flex;
}

.field {
  max-width: 100%;
  width: $inputMaxWidth;
}

.icon {
  margin-left: $gapXS;
  min-width: 24px;
  max-height: 24px;
}
