@import 'src/styles/mixins';

.checkboxRow {
  border-bottom: 1px solid $lineColor;
  margin: 0;
}

.radio {
  @include default-transition;
  margin-left: auto;

  &_hidden {
    opacity: 0;
  }
}
