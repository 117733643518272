@import 'src/styles/mixins';

.inputs {
  & > * {
    @include input-bottom-margin;
  }
}

.fileUpload {
  display: flex;
  flex-direction: column;
}
