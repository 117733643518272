@import 'src/styles/mixins';

$emptyStateHeaderFontSize: 1.8rem;

.emptyState {
  margin-bottom: $gapXL;
  margin-top: 2 * $gapXL;
  text-align: center;
  width: 100%;
}

.instruction {
  @include font-size-s;
  color: $textColorLight;
  margin-bottom: $gapXS;
}

.header {
  font-size: $emptyStateHeaderFontSize;
  margin-bottom: $gapM;
}

.actionButtonWrapper {
  margin-top: $gapXL;
}
