@import 'src/styles/mixins';

.flexColumn {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
}

.tab {
  @include default-transition;
  @include font-size-s;
  color: $textColorLight;
  display: flex;
  height: 4rem;
  padding-left: $gapM;
  padding-right: $gapM;

  &_subStep {
    padding-left: $gapXL; // 2nd step substeps should have bigger left padding
  }

  &_selected {
    background: $primaryBgColor;
    color: $primaryColor !important; // sass-lint:disable-line no-important <- override text color for disabled tab
  }

  &_configStep {
    color: $textColor;
    margin-top: $gapXL;
  }

  &_markedStep {
    &::after {
      @include size(0.7rem);
      background: $primaryColor;
      border-radius: 50%;
      content: '';
      display: inline-block;
      margin-right: 0.2rem;
    }
  }
}

.tabLabel {
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
}
