@import 'src/styles/mixins';

.container {
  padding: $gapS $gapL;
  width: 25rem;
}

.filterIcon {
  path {
    stroke: $primaryColor;
  }
}

.advancedFilterButton {
  border: 2px solid $primaryColor;
}

.header {
  @include font-size-l;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

@mixin advanced-search-section {
  display: flex;
  flex-direction: column;
  padding-top: $gapS;
}

@mixin advanced-search-description {
  color: $textColorLight;
  margin-bottom: $gapS;
  margin-top: $gapS;
}

@mixin advanced-search-checkboxes {
  @include font-size-s;
  column-gap: $gapL;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
