@import 'src/styles/mixins';

.stepNavigation {
  align-items: center;
  display: flex;
  margin-left: auto;
  column-gap: $gapL;
}

.approvalRequiredSelect {
  margin-left: $gapL;
  width: 16.5rem;
}
