@import 'src/styles/mixins';

$asideMarginRight: $gapXL;
$asideWidth: 19rem;

.topBar {
  @include default-transition;
  border: 1px solid $lineColor;
  border-radius: $borderRadius;
  display: flex;
  flex-direction: column;
  min-height: 7rem;
  padding-left: $gapM;
  padding-right: $gapM;
  position: relative;
}

.stepContainer {
  display: flex;
  flex: 1;
  margin-top: 7 * $baseGap;
}

.aside {
  border-right: 1px solid $lineColor;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 $asideWidth;
  flex-direction: column;
  margin-right: $asideMarginRight;
  width: $asideWidth;
}

.stepContent {
  padding-bottom: 2 * $gapXL;
  width: calc(100% - #{$asideWidth + $asideMarginRight});
}
