@import '../../../../../styles/mixins';

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.input {
  margin-bottom: 5 * $baseGap;
}

.error {
  @include font-size-s;
  color: $errorColor;
  margin-bottom: $gapL;
}

.button {
  margin-left: 2rem;
  min-width: 10rem;
}
