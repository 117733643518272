@import 'src/styles/mixins';

$loginFormShadowColor: rgba(74, 80, 91, 0.2);

.loginFormWrapper {
  align-items: center;
  box-shadow: 0 0 6.4rem 0 $loginFormShadowColor;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  min-width: 40%;
}

.loginForm {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-width: 28.4rem;
  position: relative;
}

.logo {
  margin-bottom: $gapXL;
}

.hero {
  align-items: center;
  display: flex;
  padding-left: 14rem;
}

$heroTitleOpacity: 0.07;

@keyframes :global(heroAppear) {
  0% {opacity: 0;}
  100% {opacity: $heroTitleOpacity;}
}

.heroTitle {
  &:global {
    animation: 0.3s ease-out 0s 1 heroAppear;
  }
  color: $textColor;
  font-family: 'Raleway', sans-serif;
  font-size: 12rem;
  font-weight: bold;
  line-height: 12.8rem;
  opacity: $heroTitleOpacity;
  word-spacing: 1000rem; // huge amount to force wrapping
}
