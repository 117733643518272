@import 'src/styles/mixins';
@import 'src/components/shared/search-bar/SearchBar.module';

.form {
  margin-bottom: $gapXS;
}

.inputs {
  @include search-bar-inputs;
}

.description {
  @include search-bar-description;
}

.reassignButton {
  margin-left: auto;
}
