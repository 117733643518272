@import 'src/styles/mixins';
@import 'src/components/shared/confirmation-popup/ConfirmationPopupSharedStyles.module';

.message {
  @include message;
}

.correctRowsInfo {
  align-content: center;
  display: flex;
  justify-content: center;
}

.icon {
  @include size(2.4rem);
}

.validIcon {
  color: $successColor;
}

.invalidIcon {
  color: $errorColor;
}

.messagePart {
  align-self: center;
}

.bold {
  @include bold;
}

