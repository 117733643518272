@import 'src/styles/mixins';

.option {
  align-items: center;
  display: flex;
}

.icon {
  margin-right: $gapXS;
}
