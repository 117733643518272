@import 'src/styles/mixins';

.input {
  flex: 1;
  margin-right: $gapXL;
  max-width: $customFieldDefaultWidth;

  &_long {
    max-width: $customFieldDefaultWidth + $customFieldDefaultIndent;
  }
}
