@import 'src/styles/mixins';

.approver {
  align-content: center;
  display: flex;
  margin-bottom: $gapL + $gapXS;
}

.branches {
  @include font-size-s;
  color: $textColorLight;
}

.roles {
  margin-top: $gapS;
}

.column {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: $gapS;
  margin-right: $gapS;
}

.comment {
  display: flex;
  margin-top: $gapM;
}

.number {
  align-items: center;
  color: $textColorLight;
  display: flex;
  height: 5 * $baseGap;
  min-width: $gapM;
}
