@import 'src/styles/mixins';

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $gapM;
}

.description {
  width: 23rem;
}
