@import 'src/styles/mixins';

@mixin search-bar-inputs {
  display: flex;
  gap: $gapS;
  margin-top: $gapXS;
}

@mixin search-bar-description {
  @include font-size-s;
  color: $textColorLight;
  margin-top: $gapXS;
}

@mixin search-icon($searchIconSize) {
  @include size($searchIconSize);
  align-items: center;
  display: flex;
  justify-content: center;
}
