@import 'src/styles/mixins';

$popupHeaderFontSize: 2.4rem;
$popupDescriptionMaxWidth: 35rem;
$popupMinWidth: 32rem;

.popup {
  min-width: $popupMinWidth;
  max-width: 2 * $popupMinWidth;
  padding: $gapXL;
}

.header {
  font-size: $popupHeaderFontSize;
  margin-bottom: $gapM;
  text-align: center;
}

.description {
  @include font-size-s;
  color: $textColorLight;
  margin-left: auto;
  margin-right: auto;
  max-width: $popupDescriptionMaxWidth;
  text-align: center;
}

.footer {
  display: flex;
  justify-content: flex-end;
  column-gap: $gapL;
  margin-top: $gapXL;
}
