@import 'src/styles/mixins';
@import 'src/components/shared/search-bar/SearchBar.module';

.inputs {
  @include search-bar-inputs;
}

.description {
  @include search-bar-description;
}
