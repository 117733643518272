@import 'src/styles/mixins';

.header {
  align-items: center;
  background: $white;
  border-bottom: 1px solid $lineColor;
  box-sizing: border-box;
  display: flex;
  height: $headerHeight;
}

.items {
  @include desktop-width;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;

  @include tablet-only {
    grid-column-gap: $gapXS;
  }
}

.item {
  align-items: center;
  display: flex;
  justify-content: center;

  &:first-child {
    justify-content: flex-start;
  }

  &:last-child {
    justify-content: flex-end;
  }
}

.logo {
  max-height: 5rem;
}

.logout {
  cursor: pointer;
  min-width: 4rem;
  position: relative;
  margin-left: $gapS;
}

