@import 'src/styles/mixins';

.column {
  display: flex;
  flex-direction: column;
}

.input {
  margin-bottom: $gapXL;
}

.header {
  @include font-size-l;
  align-items: center;
  display: flex;
  margin-bottom: $gapXL;
  margin-top: $gapXL + $gapM;
}

.removeButton,
.addButton {
  margin-left: $gapXL;
}

.row {
  align-items: center;
  display: flex;
  margin-bottom: $gapXL;
}
