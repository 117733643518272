@import 'src/styles/mixins';

.tableRow {
  @include default-transition;

  & > * {
    border-bottom: unset;
  }

  &:hover {
    background: $rowBgColor;
  }
}

.expandableDetails {
  padding-bottom: 0;
  padding-top: 0;
}

.rowDetailsClosed {
  display: none;
}

.rowDetailsOpened {
  display: block;
}

.actionCell {
  pointer-events: none;
}

.removeButton {
  height: 3rem;
  margin-top: $gapS;
}