@import 'src/styles/mixins';

.container {
  margin-top: $gapXL;
}

.relatedPerson {
  border: 1px solid $lineColor;
  padding: $gapM;
  margin-bottom: $gapXL;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actionButtons {
  display: flex;
}

.moreDetails {
  @include default-transition;
  margin-top: $gapM;
}
