@import 'src/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
}

.tabs {
  margin-top: 8 * $baseGap;
}

.stepError {
  @include font-size-s;
  color: $errorColor;
  margin-top: $gapXL;
}
