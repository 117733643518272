@import 'src/styles/mixins';

.header {
  @include font-size-l;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: $gapXL;
}

.close {
  margin-left: auto;
}

.drawer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: $drawerWidth;
  padding-bottom: $gapXL;
  padding-left: $gapXL + $gapS;
  padding-right: $gapXL + $gapS;
  padding-top: $gapXL;
}
