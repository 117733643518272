@import 'src/styles/mixins';

.eventLogsDrawer {
  padding: 6rem;
  width: 70vw;
}

.header {
  @include font-size-xl;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: $gapS;
}

.description {
  @include font-size-s;
  color: $textColorLight;
  margin-bottom: $gapXL;
}
