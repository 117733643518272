@import 'src/styles/mixins';

.input {
  margin-bottom: $gapXL;
}

.column {
  display: flex;
  flex-direction: column;
}
