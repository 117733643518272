@import 'src/styles/mixins';

.switch {
  @include input-bottom-margin;
  align-items: center;
  display: flex;
  justify-content: center;
}

.button {
  min-width: 11.6rem;
  transition: 0.4s ease;
  width: 100%;

  &_left {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  &_right {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  &:disabled {
    border-color: $disabledColor;
    color: $disabledTextColor;
  }

  &_active {
    &:disabled {
      background: $disabledColor;
    }
  }
}
