@import 'src/styles/mixins';

.channels {
  align-items: center;
  display: flex;
}

.channel {
  margin-right: $gapXS;
}
