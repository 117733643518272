@import 'src/styles/mixins';

.schedule {
  flex: 1;
  margin-top: 2 * $gapXL;
  width: 100%;
}

.scheduleHeader {
  @include font-size-l;
  margin-bottom: $gapXL;
}

.headerCell {
  max-width: 7rem;
}

.currencyCell {
  text-align: end;
}
