@import 'src/styles/mixins';

$lightFieldsBackground: #fafafa;

.items {
  @include default-transition;
  border-radius: $borderRadius;

  &_isDraggingOver {
    background: $lightFieldsBackground;
  }
}
