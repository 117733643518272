@import 'src/styles/mixins';

$printIconSize: 3.6rem;

.printButton {
  @include default-transition;
  border: 2px solid $primaryColor;
  border-radius: $borderRadius;

  path {
    fill: $primaryColor;
  }
}

.buttonDisabled {
  border: 2px solid $disabledColor;

  path {
    fill: $disabledColor;
  }
}
