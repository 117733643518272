@import 'src/styles/mixins';

.instruction {
  @include font-size-s;
  color: $textColorLight;
  margin-bottom: $gapXL;
  margin-top: $gapL;
  width: 100%;

  &_defaultFields {
    color: $textColor;
    margin-bottom: $gapXL + $gapM;
    margin-top: $gapXL + $gapM;
  }
}
