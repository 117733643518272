@import 'src/styles/mixins';

.fileUploadWrapper {
  & > * {
    margin-bottom: 0;
  }
}

.fileUpload {
  flex-direction: column;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: $inputMaxWidth + $gapM;
}

.fields {
  & > * {
    @include input-bottom-margin;
  }
}

