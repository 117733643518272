@import 'src/styles/mixins';

.tableRow {
  @include default-transition;
  cursor: pointer;

  &:hover {
    background: $rowBgColor;
  }
}
