@import 'src/styles/mixins';

.title {
  @include rounded-corners;
  align-items: center;
  background: $emptyPageBackground;
  display: flex;
  font-size: 2rem;
  height: 50rem;
  justify-content: center;
  margin-bottom: $gapL;
  margin-top: $gapL;
  width: 100%;
}
