@import 'src/styles/mixins';

.status {
  align-items: center;
  border-radius: $borderRadius;
  display: flex;
  height: 5 * $baseGap;
  justify-content: center;
  padding-left: $gapS;
  padding-right: $gapS;

  &_warning {
    background: $warningBgColor;
    color: $warningColor;
  }

  &_success {
    background: $successBgColor;
    color: $successColor;
  }

  &_error {
    background: $errorBgColor;
    color: $errorColor;
  }
}

.message{
  margin-left: $gapS;
}
