@import 'src/styles/mixins';

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.minMaxField{
  display: flex;
  flex-direction: row;
}

.principal {
  display: flex;
  max-width: 100%;
}

.principalInput {
  max-width: 295px;
}
