@import 'src/styles/mixins';

.status {
  @include rounded-corners;
  align-items: center;
  background: $inputBgColor;
  color: $textColor;
  display: flex;
  font-weight: bold;
  height: 2.5rem;
  justify-content: center;
  padding-left: $gapS;
  padding-right: $gapS;
  width: fit-content;

  &_approval {
    background: $warningBgColor;
    color: $warningColor;
  }

  &_input, &_application {
    background: $primaryBgColor;
    color: $primaryColor;
  }

  &_status {
    background: $successColor;
    color: $successBgColor;
  }
}
