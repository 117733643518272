@import 'src/styles/mixins';
@import 'src/components/shared/confirmation-popup/ConfirmationPopupSharedStyles.module';

.message {
  @include message;
  gap: unset;
}

.confirmButton {
  background: $primaryColor;
}

.bold {
  @include bold;
}

.messagePart {
  display: block;
}

.dropdown {
  text-align: start;
  margin-top: $gapL;
}
