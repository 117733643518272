@import 'src/styles/mixins';

.description {
  margin-bottom: $gapM;
  flex-direction: column;
}

.productName {
  font-weight: bold;
  color: $textColor;
}
