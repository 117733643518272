@import 'src/styles/mixins';

.fieldInput {
  flex: 1;
  margin-right: $gapXL;
  max-width: $customFieldDefaultWidth;

  &_type {
    max-width: $customFieldDefaultWidth + $customFieldDefaultIndent;
  }
}

.bin {
  margin-left: $gapM;
}
