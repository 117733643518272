@import 'src/styles/mixins';

.activeFilters {
  @include font-size-s;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: $gapS;
}

.chip {
  align-items: center;
  background: $primaryBgColor;
  border-radius: 1rem;
  color: $primaryColor;
  display: inline-flex;
  height: 2.5rem;
  justify-content: center;
  margin-left: $gapS;
  padding-left: $gapS;
  padding-right: $gapS;
}

.icon {
  @include size(1.5rem);
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-left: $gapXS;
}

.reassignButton {
  margin-left: auto;
}