@import 'src/styles/mixins';

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 2 * $gapXL;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: $gapL;
  width: 100%;
}

.tabsWrapper {
  width: 100%;
}
